import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {

  menu: any = false;
  
  constructor(private router: Router,) { }

  ngOnInit(): void {
    //console.log(this.router.url);
  }

  showMenu(): void{
    this.menu = !this.menu;
  }

  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event){
    let menu  = document.querySelector('mat-toolbar');
    if(window.scrollY < 10){
      menu.classList.remove("menu2");
    }else{
      menu.classList.add("menu2");

    }
  } 

}
