<app-header></app-header>

<section class="header"> 
    <h1 class="centered"  >Usluge</h1>
  </section>

<section class="service">
    <div *ngFor="let service of services" class="service-box" >
        <div>
            <h2 class="service-title">{{service.title}}</h2>
        </div>

        <div class="service-image-container">
            <img src="{{service.imageUrl}}" alt="{{service.imageDescription}}" class="service-image">
        </div>

        <div>
            <p class="service-description">{{service.longDescription}}</p>
        </div>

         <div>
            <p class="service-description" *ngIf="service.additionalDescription">{{service.additionalDescription}}</p>
        </div>
        
         <div>
            <ul>
                <li class="service-description" *ngFor='let item of service.listOfItems'>
                    {{item}}
                </li>
            </ul>
        </div>

    </div>
</section>

<section class="contact">
    <div class="icons">
        <a href="https://facebook.com/MBAautocentar" target="_blank" >
            <img class="social-logo" src="./assets/images/facebok.png" alt="Facebook">
        </a>
        <a href="http://instagram.com/mbaautocentar" target="_blank" >
            <img class="social-logo"  src="./assets/images/instagram.png" alt="Instagram">
        </a>
  
        <a href="tel:+38268555005" target="_blank" >
            <img class="social-logo"  src="./assets/images/phone.png" alt="phone">
        </a>
    </div>
  
    <div class="contact-phone">
        <p >Kontakt telefon: +382 68 555 005</p>
        <p style="color: transparent;">Kontakt telefon: <span style="color: white">+382 67 365 555</span></p>
        <p class="addres-footer">Donji Kokoti b.b, Podgorica, Crna Gora</p>
    </div>
  
  </section>