<app-header></app-header>

<section class="header"> 
    <h1 class="centered"  >Kontakt</h1>
  </section>


<section class="contact-screen-map"  >
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d889.9949880769555!2d19.194811095699418!3d42.40239246073085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134debc1935a289d%3A0x726b46e88afdf834!2sMBA%20Auto!5e1!3m2!1sen!2s!4v1618439919447!5m2!1sen!2s" width="100%" height="100%" style="border:0;" async></iframe>
  </section>

  <section class="contact">
    <div class="icons">
        <a href="https://facebook.com/MBAautocentar" target="_blank" >
            <img class="social-logo" src="./assets/images/facebok.png" alt="Facebook">
        </a>
        <a href="http://instagram.com/mbaautocentar" target="_blank" >
            <img class="social-logo"  src="./assets/images/instagram.png" alt="Instagram">
        </a>

        <a href="tel:+38268555005" target="_blank" >
            <img class="social-logo"  src="./assets/images/phone.png" alt="phone">
        </a>
    </div>

    <div class="contact-phone">
        <p >Kontakt telefon: +382 68 555 005</p>
        <p style="color: transparent;">Kontakt telefon: <span style="color: white">+382 67 365 555</span></p>
        <p class="addres-footer">Donji Kokoti b.b, Podgorica, Crna Gora</p>
    </div>

</section>
