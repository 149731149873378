<app-header></app-header>

<section class="header"> 
  <h1 class="centered"  >Servis i popravka automatskih mjenjač</h1>
</section>


<section class="screen-about-us-background">
    <img src="./assets/images/logo.jpg" alt="MBA Auto" class="screen-about-us-logo-image">
    <section class="screen-about-us-text">
      <p>MBA Auto Centar je firma koja je počela sa radom početkom 90-tih, kao auto-otpad specijalizovan za prodaju polovnih i novih djelova iz programa VW, Audi, Škoda, i Seat. Time se bavimo i dan danas.</p>
      <br>
      <p>Posle decenijskog rada stekli su se uslovi da se proširi djelatnost i samim tim smo otvorili auto servis koji se isključivo bavi servisom i opravkom automatskih mjenjača. U preko 200 kvadrata nalazi se najopremljeniji servis za opravku automatskih mjenjača u regionu. Kvalitet opreme i stručnost osoblja za opravku su bez konkurencije u Crnoj Gori.</p>
    </section>
</section>

<section class="contact">
  <div class="icons">
      <a href="https://facebook.com/MBAautocentar" target="_blank" >
          <img class="social-logo" src="./assets/images/facebok.png" alt="Facebook">
      </a>
      <a href="http://instagram.com/mbaautocentar" target="_blank" >
          <img class="social-logo"  src="./assets/images/instagram.png" alt="Instagram">
      </a>

      <a href="tel:+38268555005" target="_blank" >
          <img class="social-logo"  src="./assets/images/phone.png" alt="phone">
      </a>
  </div>

  <div class="contact-phone">
      <p >Kontakt telefon: +382 68 555 005</p>
      <p style="color: transparent;">Kontakt telefon: <span style="color: white">+382 67 365 555</span></p>
      <p class="addres-footer">Donji Kokoti b.b, Podgorica, Crna Gora</p>
  </div>

</section>