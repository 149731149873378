import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ServiceComponent } from './service/service.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  { path: '', redirectTo: 'pocetna', pathMatch: 'full'},
  { path: 'pocetna',  component: HomeComponent},
  { path: 'onama', component: AboutComponent},
  { path: 'servis', component: ServiceComponent},
  { path: 'kontakt', component: ContactComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes , { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
