<app-header></app-header>

<section class="slider">
        <ng-image-slider 
        [images]="imageObject"
        [imagePopup]=false
        [imageSize]="{width: '100%', height:  innerWidth + 'px' , space: 0}"
        [infinite]="true"
        [showArrow]=false
        [autoSlide]="{interval: 3, stopOnHover: false}"
    >
        </ng-image-slider>


    <h1 class="bottom-left" *ngIf="size  > 600" >SERVIS I POPRAVKA AUTOMATSKIH MJENJAČA - PODGORICA, CRNA GORA</h1>
   
</section>

<section class="about-us">
    <h2>6 godina iskustva u radu sa automatskim mjenjačima!</h2>
    <img src="./assets/images/settings.png" alt="automatski mjenjac">
    <p>U preko 200 kvadrata nalazi se najopremljeniji servis za opravku automatskih mjenjača u regionu. Kvalitet opreme i stručnost osoblja za opravku su bez konkurencije u Crnoj Gori!</p>
</section>


<section class="service">
    <div *ngFor="let service of services" class="service-box" >
        <div>
            <h2 class="service-title">{{service.title}}</h2>
        </div>

        <div class="service-image-container">
            <img src="{{service.imageUrl}}" alt="{{service.imageDescription}}" class="service-image">
            <div class="overlay">
                <a href="/" class="text" routerLink="/servis">Više...</a>
            </div>
        </div>

        <div>
            <p class="service-description">{{service.description}}</p>
        </div>

    </div>
</section>

<section class="contact">
    <div class="icons">
        <a href="https://facebook.com/MBAautocentar" target="_blank" >
            <img class="social-logo" src="./assets/images/facebok.png" alt="Facebook">
        </a>
        <a href="http://instagram.com/mbaautocentar" target="_blank" >
            <img class="social-logo"  src="./assets/images/instagram.png" alt="Instagram">
        </a>

        <a href="tel:+38268555005" target="_blank" >
            <img class="social-logo"  src="./assets/images/phone.png" alt="phone">
        </a>
    </div>

    <div class="contact-phone">
        <p >Kontakt telefon: +382 68 555 005</p>
        <p style="color: transparent;">Kontakt telefon: <span style="color: white">+382 67 365 555</span></p>
        <p class="addres-footer">Donji Kokoti b.b, Podgorica, Crna Gora</p>
    </div>

</section>

<section class="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d889.9949880769555!2d19.194811095699418!3d42.40239246073085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134debc1935a289d%3A0x726b46e88afdf834!2sMBA%20Auto!5e1!3m2!1sen!2s!4v1618439919447!5m2!1sen!2s" width="100%" height="100%" style="border:0;" async></iframe>
</section>
