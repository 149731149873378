import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public innerWidth: any;
  public size : any;
  imageObject: Array<object> = [
    {
      image: './assets/images/menjac1.jpg',
      thumbImage: './assets/images/menjac1.jpg',
      alt: 'Menjac',
    },
    {
      image: './assets/images/menjac2.jpg',
      thumbImage: './assets/images/menjac2.jpg',
      alt: 'Menjac',
    },
    {
      image: './assets/images/menjac3.jpg',
      thumbImage: './assets/images/menjac3.jpg',
      alt: 'Menjac',
    },
    {
      image: './assets/images/menjac4.jpg',
      thumbImage: './assets/images/menjac4.jpg',
      alt: 'Menjac',
    }
  ];

  services = [
    {
      title: 'Servis',
      description: 'Servis i održavanje su najbitnije stavke kod preventivnog održavanja automatskih mjenjača kako bi se spriječile veće havarije na samom automatskom mjenjaču.',
      longDescription: 'Servis i održavanje su najbitnije stavke kod preventivnog održavanja automatskih mjenjača kako bi se spriječile veće havarije na samom automatskom mjenjaču. S obzirom na to da je automatski mjenjač izuzetno složena i skupa komponenta svakog automobila, izuzetno je bitno preventivno održavanje. Interval redovnog servisa zavisi od proizvođača, ali generalno je preporuka da se radi u intervalu od 60 do 80 hiljada km. Servis automatskih mjenjača podrazumijeva:',
      additionalDescription: false,
      imageUrl: './assets/images/service.jpg',
      imageDescription: 'Servis automatskih mjenjača',
      listOfItems: [
        'Zamjenu ulja i filtera na mjenjaču',
        'Čišćenje mjenjača iznutra sa posebnom mašinom',
        'Kompjutersku dijagnostiku mjenjača',
        'Testiranje rada mjenjača u vožnji'
      ]
    },
    {
      title: 'Remont i popravka',
      description: 'Posjetite nas i mi ćemo se pobrinuti da vaš automobil u najkraćem mogućem roku bude siguran na drumu.',
      longDescription: 'Posjetite nas i mi ćemo se pobrinuti da vaš automobil u najkraćem mogućem roku bude siguran na drumu.',
      additionalDescription: false,
      imageUrl: './assets/images/remont.jpg',
      imageDescription: 'Remont i popravka automatskih mjenjača',
      listOfItems: [
        '6 godina iskustva u radu sa automatskim mjenjačima',
        'Rad sa novim i originalnim djelovima',
        'Profesionalni tim dijagnostičara i mehaničara',
        'Garancija na zamijenjene djelove i popravke',
        'Brza i kvalitetna usluga',
        'Povoljne cijene'
      ]
    },
    {
      title: 'Dijagnostika',
      description: 'U našem servisu vršimo kompjutersku dijagnostiku automatskih mjenjača na svim vrstama automobila, sa najsavremenijim dijagnostičkim uređajima.',
      longDescription: 'U našem servisu vršimo kompjutersku dijagnostiku automatskih mjenjača na svim vrstama automobila, sa najsavremenijim dijagnostičkim uređajima. Kompjuterska dijagnostika podrazumijeva:',
      additionalDescription: 'Pored kompjuterske dijagnostike vršimo i ispitivanje hidrobloka i solenoida na posebnim uređajima, da bismo sa sigurnošću mogli utvrditi uzrok nepravilnog rada automatskog mjenjača.',
      imageUrl: './assets/images/diagnostics.jpg',
      imageDescription: 'Dijagnostika kvara kod automatskih mjenjača',
      listOfItems: [
        'Očitavanje i brisanje grešaka',
        'Adaptaciju i programiranje kontrolne jedinice mjenjača',
        'Adaptaciju pojedinih komponenti mjenjača',
        'Očitavanje trenutnih mjernih vrijednosti senzora u mjenjaču',
        'Testiranje pojedinačnih komponenti mjenjača',
        'Reset servisnih intervala'
      ]
    },
  ];


  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.size = window.innerWidth;
    console.log(window.innerHeight + ' ' + window.innerWidth)
    if(this.innerWidth > window.innerHeight){
      if(window.innerWidth - window.innerHeight < 200)
        this.innerWidth = window.innerHeight - 200;
      else if(window.innerWidth - window.innerHeight < 250)
        this.innerWidth = window.innerHeight - 250;
      else if(window.innerWidth - window.innerHeight < 300)
        this.innerWidth = window.innerHeight - 300;
      else if(window.innerWidth - window.innerHeight < 350)
        this.innerWidth = window.innerHeight - 150;
      else if(window.innerWidth - window.innerHeight < 400)
        this.innerWidth = window.innerHeight - 200;
      else if(window.innerWidth - window.innerHeight < 450)
        this.innerWidth = window.innerHeight - 200;
      else if(window.innerWidth - window.innerHeight < 500)
        this.innerWidth = window.innerHeight - 200;
      else
        this.innerWidth = window.innerHeight;
    }
  }

}
