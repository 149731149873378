import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  services = [
    {
      title: 'Servis',
      description: 'Servis i održavanje su najbitnije stavke kod preventivnog održavanja automatskih mjenjača kako bi se spriječile veće havarije na samom automatskom mjenjaču.',
      longDescription: 'Servis i održavanje su najbitnije stavke kod preventivnog održavanja automatskih mjenjača kako bi se spriječile veće havarije na samom automatskom mjenjaču. S obzirom na to da je automatski mjenjač izuzetno složena i skupa komponenta svakog automobila, izuzetno je bitno preventivno održavanje. Interval redovnog servisa zavisi od proizvođača, ali generalno je preporuka da se radi u intervalu od 60 do 80 hiljada km. Servis automatskih mjenjača podrazumijeva:',
      additionalDescription: false,
      imageUrl: 'https://i.imgur.com/XXijvsN.jpg',
      imageDescription: 'Servis automatskih mjenjača',
      listOfItems: [
        'Zamjenu ulja i filtera na mjenjaču',
        'Čišćenje mjenjača iznutra sa posebnom mašinom',
        'Kompjutersku dijagnostiku mjenjača',
        'Testiranje rada mjenjača u vožnji'
      ]
    },
    {
      title: 'Remont i popravka',
      description: 'Posjetite nas i mi ćemo se pobrinuti da vaš automobil u najkraćem mogućem roku bude siguran na drumu.',
      longDescription: 'Posjetite nas i mi ćemo se pobrinuti da vaš automobil u najkraćem mogućem roku bude siguran na drumu.',
      additionalDescription: false,
      imageUrl: 'https://i.imgur.com/9fLQL4Q.jpg',
      imageDescription: 'Remont i popravka automatskih mjenjača',
      listOfItems: [
        '6 godina iskustva u radu sa automatskim mjenjačima',
        'Rad sa novim i originalnim djelovima',
        'Profesionalni tim dijagnostičara i mehaničara',
        'Garancija na zamijenjene djelove i popravke',
        'Brza i kvalitetna usluga',
        'Povoljne cijene'
      ]
    },
    {
      title: 'Dijagnostika',
      description: 'U našem servisu vršimo kompjutersku dijagnostiku automatskih mjenjača na svim vrstama automobila, sa najsavremenijim dijagnostičkim uređajima.',
      longDescription: 'U našem servisu vršimo kompjutersku dijagnostiku automatskih mjenjača na svim vrstama automobila, sa najsavremenijim dijagnostičkim uređajima. Kompjuterska dijagnostika podrazumijeva:',
      additionalDescription: 'Pored kompjuterske dijagnostike vršimo i ispitivanje hidrobloka i solenoida na posebnim uređajima, da bismo sa sigurnošću mogli utvrditi uzrok nepravilnog rada automatskog mjenjača.',
      imageUrl: 'https://i.imgur.com/z7YYn6I.jpg',
      imageDescription: 'Dijagnostika kvara kod automatskih mjenjača',
      listOfItems: [
        'Očitavanje i brisanje grešaka',
        'Adaptaciju i programiranje kontrolne jedinice mjenjača',
        'Adaptaciju pojedinih komponenti mjenjača',
        'Očitavanje trenutnih mjernih vrijednosti senzora u mjenjaču',
        'Testiranje pojedinačnih komponenti mjenjača',
        'Reset servisnih intervala'
      ]
    },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
