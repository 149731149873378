<mat-toolbar [ngStyle]="{'background': menu ? '#2c2d2e93' : ''}">  
    <mat-toolbar-row>
        <span class="mat-logo"><a routerLink="/pocetna" class="mat-logo"><img src="./assets/images/logo.jpg" alt="mba logo"/></a></span>
        <span class="example-spacer"></span>

        <p class="mat-link">
            <a routerLink="/pocetna" routerLinkActive="active">
                Naslovna
            </a>
        </p>
        <p class="mat-link">
            <a routerLink="/onama" routerLinkActive="active">
                O nama
            </a>
        </p>
        <p class="mat-link">
            <a routerLink="/servis" routerLinkActive="active">
                Usluge
            </a>
        </p>
        <p class="mat-link">
            <a routerLink="/kontakt" routerLinkActive="active">
                    Kontakt
            </a>
        </p>

        <span  class="mat-link-mobile" (click)="showMenu()">
            <svg xmlns="http://www.w3.org/2000/svg" height="38px" viewBox="0 0 24 24" width="38px" fill="white">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
            </svg>
        </span>
    </mat-toolbar-row>
</mat-toolbar>


<div *ngIf="menu" class="nav-menu">

    <div class="nav-item">
        <a routerLink="/pocetna" routerLinkActive="active" >
            Naslovna
        </a>
    </div>


    <div class="nav-item">
        <a routerLink="/onama" routerLinkActive="active" >
            O nama
        </a>
    </div>

    <div  class="nav-item">
        <a routerLink="/servis" routerLinkActive="active" >
            Usluge
        </a>
    </div>

    <div class="nav-item">
        <a routerLink="/kontakt" routerLinkActive="active"  >
            Kontakt
        </a>
    </div>

</div>